import { ToastContainer } from "react-toastify";
import "./assets/css/globale.css";
import BookingForm from "./components/BookingForm";
import Footer from "./components/Footer";
import Header from "./components/Header";
import InstaWall from "./components/InstaWall";
import MainHero from "./components/MainHero";
import Menu from "./components/Menu";
import WelcomeSection from "./components/WelcomeSection";

function App() {
  return (
    <main>
      <Header />
      <MainHero />
      <WelcomeSection />
      <BookingForm />
      <hr />
      <Menu />
      <hr />
      <InstaWall />
      <Footer />
      <ToastContainer />
    </main>
  );
}

export default App;
