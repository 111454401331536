import { FaGoogle, FaInstagram, FaTripadvisor } from "react-icons/fa";
import Lenoeldesacha from "../assets/img/lenoeldesacha.png";

const Footer = () => {
  return (
    <footer className="bg-white text-gray-800">
      <div className="mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 md:grid-cols-4 gap-8 relative py-8">
        {/* Locations */}
        <div>
          <h3 className="text-lg md:text-2xl font-semibold text-primary mb-4 uppercase">
            Adresse
          </h3>
          <p>3 Rue René Boulanger, 75010 Paris</p>
        </div>

        <div className="hidden md:block absolute top-0 bottom-0 left-1/4 border-l border-gray-300"></div>

        <div>
          <h3 className="text-lg md:text-2xl font-semibold text-primary mb-4">
            OPENING HOURS
          </h3>
          <p>
            <strong>Lundi au Samedi</strong>
            <br />
            12h00 - 15h00 & 18h30 - 23h00
          </p>
        </div>

        {/* Vertical Separator */}
        <div className="hidden md:block absolute top-0 bottom-0 left-1/2 border-l border-gray-300"></div>

        {/* Contact Us */}
        <div>
          <h3 className="text-lg md:text-2xl font-semibold text-primary mb-4 uppercase">
            Contactez-nous
          </h3>
          <p>contact@lefilrougecafe.com</p>
          <div className="mt-4 space-y-2 flex flex-col">
            <a
              href="https://www.instagram.com/le.fil.rouge.cafe/?hl=fr"
              className="flex items-center space-x-2 text-gray-700 hover:text-primary"
            >
              <FaInstagram className="text-xl" />
              <span>Instagram</span>
            </a>
            <a
              href="https://g.co/kgs/J9yspZC"
              className="flex items-center space-x-2 text-gray-700 hover:text-primary"
            >
              <FaGoogle className="text-xl" />
              <span>Google</span>
            </a>
            <a
              href="https://www.tripadvisor.fr/Restaurant_Review-g187147-d3309204-Reviews-Le_Fil_Rouge_Cafe-Paris_Ile_de_France.html?m=69573"
              className="flex items-center space-x-2 text-gray-700 hover:text-primary"
            >
              <FaTripadvisor className="text-xl" />
              <span>Tripadvisor</span>
            </a>
          </div>
        </div>

        {/* Vertical Separator */}
        <div className="hidden md:block absolute top-0 bottom-0 left-3/4 border-l border-gray-300"></div>

        {/* Our Partners */}
        <div>
          <h3 className="text-lg md:text-2xl font-semibold text-primary mb-4">
            NOS PARTENAIRES
          </h3>
          <div className="flex space-x-4">
            <img
              src={Lenoeldesacha}
              alt="Le Noël de Sacha"
              className="w-32 h-32 object-contain"
            />
          </div>
        </div>
      </div>
      <div className="mt-8 text-center text-gray-500 bg-black text-white py-4">
        © LEFILROUGECAFÉ - 2025
      </div>
    </footer>
  );
};

export default Footer;
