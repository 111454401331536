import { FaInstagram } from "react-icons/fa";
import "swiper/css";
import "swiper/css/navigation";
import Menu2 from "../assets/img/menu/menu2.jpg";

const Menu = () => {
  return (
    <div id="menu" className="bg-secondary text-white py-12">
      {/* Titre */}
      <div className="text-center mb-8">
        <h2 className="text-2xl md:text-4xl font-bold uppercase">Menu</h2>
      </div>

      <img
        src={Menu2}
        alt="Menu"
        className="w-full max-w-5xl object-cover rounded-lg shadow-md mx-auto"
      />

      <div className="flex justify-center mt-8 text-center">
        <a
          href="/menu.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="text-lg md:text-xl font-semibold text-white hover:underline flex items-center justify-center space-x-2 uppercase bg-primary p-3 rounded transition-underline"
        >
          <span>Voir en version pdf</span>
          <FaInstagram />
        </a>
      </div>
    </div>
  );
};

export default Menu;
