import React, { useState, useEffect } from "react";
import Logo from "../assets/img/lefilrougecafe.png";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Gérer l'état du scroll
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`fixed top-0 left-0 w-full h-20 z-50 transition-all duration-300 ${
        isScrolled || isMenuOpen
          ? "bg-white py-2 shadow-md"
          : "bg-transparent py-4"
      } flex items-center`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full flex items-center justify-between">
        {/* Menu burger à gauche */}
        <div className="flex items-center">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="relative w-8 h-8 flex flex-col justify-center items-center focus:outline-none"
          >
            <span
              className={`absolute top-1/2 left-0 w-full h-[2px] ${
                isScrolled || isMenuOpen ? "bg-black" : "bg-white"
              } rounded-full transition-transform duration-300 origin-center ${
                isMenuOpen ? "rotate-45 translate-y-0" : "-translate-y-2"
              }`}
            ></span>
            <span
              className={`absolute top-1/2 left-0 w-full h-[2px] ${
                isScrolled || isMenuOpen ? "bg-black" : "bg-white"
              } rounded-full transition-opacity duration-300 ${
                isMenuOpen ? "opacity-0" : "opacity-100"
              }`}
            ></span>
            <span
              className={`absolute top-1/2 left-0 w-full h-[2px] ${
                isScrolled || isMenuOpen ? "bg-black" : "bg-white"
              } rounded-full transition-transform duration-300 origin-center ${
                isMenuOpen ? "-rotate-45 translate-y-0" : "translate-y-2"
              }`}
            ></span>
          </button>
        </div>

        {/* Logo au centre */}
        <div
          className={`flex ${
            isScrolled || isMenuOpen ? "opacity-100" : "opacity-0"
          } justify-center items-center`}
        >
          <h1 className="text-red-600 text-xl md:text-3xl">LeFilRougeCafé</h1>
        </div>

        {/* Sélection de la langue à droite */}
        <div
          className={`flex items-center space-x-4 ${
            isScrolled || isMenuOpen ? "text-black" : "text-white"
          } text-sm`}
        >
          <button className="hover:underline">FR</button>
          <button className="hover:underline">EN</button>
        </div>
      </div>

      {/* Menu burger ouvert */}
      <div
        className={`absolute top-full left-0 w-full bg-white text-black py-6 transform transition-transform duration-500 ${
          isMenuOpen ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-4"
        }`}
      >
        <nav className="flex flex-col md:flex-row md:justify-center md:space-x-8 items-center space-y-4 md:space-y-0">
          <a href="#a-propos" className="hover:text-primary transition">
            À PROPOS
          </a>
          <a
            href="#je-reserve"
            className="hover:text-primary transition uppercase"
          >
            Réservation
          </a>
          <a href="#insta-wall" className="hover:text-primary transition">
            INSTA WALL
          </a>
          <a href="#menu" className="hover:text-primary transition">
            MENU
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
