import { FaChevronDown } from "react-icons/fa";
import Main from "../assets/img/main.png";

const MainHero = () => {
  return (
    <div
      className="relative w-full h-screen bg-cover bg-center"
      style={{
        backgroundImage: `url(${Main})`,
      }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-30"></div>

      {/* Content */}
      <div className="relative z-10 flex flex-col items-center justify-center text-white text-center h-full">
        {/* Logo */}
        <div className="mb-4">
          <div className="md:w-[34rem] w-[20rem] flex items-center justify-center mx-auto">
            <h1 className="text-white text-5xl md:text-8xl">LeFilRougeCafé</h1>
          </div>
        </div>

        {/* Adresse */}
        <p className="mt-4 md:text-5xl text-2xl px-4 md:px-0 font-light tracking-wider uppercase">
          3 Rue René Boulanger, 75010 Paris
        </p>

        <a href="tel:+33140189217" className="mt-3 text-xl hover:underline">
          01.40.18.92.17
        </a>

        <div className="mt-10 flex items-center justify-center gap-3 mt-5">
          <a
            href="/#je-reserve"
            className="w-32 bg-primary text-white py-2 px-4 rounded-lg hover:opacity-75 transition duration-200 uppercase"
          >
            Je réserve
          </a>
          <a
            href="/#menu"
            className="w-32 bg-primary text-white py-2 px-4 rounded-lg hover:opacity-75 transition duration-200 uppercase"
          >
            Menu
          </a>
        </div>

        {/* Chevron */}
        <a href="#je-reserve" className="absolute bottom-8 animate-bounce">
          <FaChevronDown className="text-2xl" />
        </a>
      </div>
    </div>
  );
};

export default MainHero;
