import Lfrc22 from "../assets/img/instawall/lfrc-22.jpg";

const WelcomeSection = () => {
  return (
    <section className="bg-white w-full h-full">
      <div className="flex flex-col md:flex-row items-center w-full h-full">
        {/* Image */}
        <div className="md:w-1/2 w-full h-full">
          <img
            src={Lfrc22}
            alt="Restaurant ambiance"
            className="w-full h-full object-cover"
          />
        </div>

        {/* Texte */}
        <div className="md:w-1/2 w-full flex flex-col justify-center items-center text-center p-8">
          <h1 className="text-2xl md:text-4xl font-bold text-gray-900 mb-6">
            BIENVENUE AU <span className="text-primary">FILROUGECAFÉ</span> !
          </h1>
          <p className="text-gray-700 text-base md:text-lg leading-relaxed mb-8 max-w-lg">
            Le restaurant LeFilRougeCafé une diversité et une qualité
            irréprochable de spécialités françaises cuisinées dans l’art du
            savoir faire d’antan et de burgers maison viande charolaise origine
            FRANCE pain artisanal du jour frites maison à la chaire tendre et
            croustillante. Découvrez un Lieu Authentiquement Américain.
          </p>
          <a
            href="#je-reserve"
            className="inline-block text-lg font-semibold text-black hover:text-primary border-b-2 border-black hover:border-yellow-600 transition"
          >
            Je réserve
          </a>
        </div>
      </div>
    </section>
  );
};

export default WelcomeSection;
