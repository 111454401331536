import { FaInstagram } from "react-icons/fa";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Ensure TypeScript understands Webpack's require.context
declare const require: {
  context: (
    directory: string,
    useSubdirectories: boolean,
    regExp: RegExp
  ) => {
    keys: () => string[];
    (id: string): string;
  };
};

// Function to import images dynamically
const importAll = (r: ReturnType<typeof require.context>): string[] =>
  r.keys().map((key) => r(key));

const images: string[] = importAll(
  require.context("../assets/img/instawall", false, /\.(png|jpe?g|svg)$/)
);

const InstaWall = () => {
  return (
    <div id="insta-wall" className="bg-secondary text-white py-12">
      {/* Titre */}
      <div className="text-center mb-8">
        <h2 className="text-2xl md:text-4xl font-bold uppercase">Insta Wall</h2>
      </div>

      {/* Swiper Galerie */}
      <div className="px-4">
        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={16}
          slidesPerView={1.2} // Mieux pour mobile
          breakpoints={{
            640: { slidesPerView: 2, spaceBetween: 16 }, // Tablettes
            768: { slidesPerView: 2.3, spaceBetween: 16 }, // Tablettes larges
            1024: { slidesPerView: 3, spaceBetween: 20 }, // Desktop
            1280: { slidesPerView: 3.3, spaceBetween: 20 }, // Grand écran
          }}
          navigation={{
            prevEl: ".slider__button-prev",
            nextEl: ".slider__button-next",
          }}
          autoplay
          className="gallery__slider"
        >
          {images.map((src, index) => (
            <SwiperSlide key={index}>
              <div className="w-full h-full bg-white border-8 border-white shadow-lg overflow-hidden">
                <img
                  src={src}
                  alt={"Image"}
                  className="w-full h-[437px] object-cover"
                />
              </div>
            </SwiperSlide>
          ))}

          <button
            type="button"
            className="slider__button-prev flex items-center justify-center absolute left-0 right-auto top-1/2 w-12 h-12 bg-neutral-900 z-50 cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="white"
            >
              <path d="M14.557 16.214a1.66 1.66 0 010 2.263l-.535.565c-.023.023-.046.047-.07.068a1.474 1.474 0 01-2.084-.068l-6.956-7.314c-.012-.012-.025-.015-.037-.025l-.537-.566A1.655 1.655 0 013.893 10a1.637 1.637 0 01.444-1.136l.537-.567c.012-.011.025-.011.037-.024L11.867.96a1.475 1.475 0 012.154 0l.535.567a1.656 1.656 0 010 2.26L8.644 10l5.913 6.214z"></path>
            </svg>
          </button>
          <button
            type="button"
            className="slider__button-next flex items-center justify-center absolute right-0 left-auto top-1/2 w-12 h-12 bg-neutral-900 z-50 cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="white"
            >
              <path d="M11.25 10L5.337 3.786a1.656 1.656 0 010-2.26l.536-.567a1.475 1.475 0 012.154 0l6.956 7.313c.012.014.025.014.037.024l.537.567c.289.307.447.713.445 1.135.002.422-.157.828-.445 1.137l-.537.566c-.012.011-.025.014-.037.025L8.027 19.04a1.475 1.475 0 01-2.154 0l-.536-.565a1.66 1.66 0 010-2.263L11.25 10z"></path>
            </svg>
          </button>
        </Swiper>
      </div>

      {/* Bouton CTA */}
      <div className="mt-8 text-center">
        <a
          href="https://www.instagram.com/le.fil.rouge.cafe/?hl=fr"
          target="_blank"
          rel="noopener noreferrer"
          className="text-lg md:text-xl font-semibold text-white hover:underline flex items-center justify-center space-x-2 uppercase"
        >
          <span>Suivez nous sur Instagram</span>
          <FaInstagram />
        </a>
      </div>
    </div>
  );
};

export default InstaWall;
