import { BookingFormData } from "../components/BookingForm";

const booking = async (data: BookingFormData) => {
  const response = await fetch("https://lenoeldesacha.com/api/booking", {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) throw new Error("Fetch failed");
};

export default booking;
