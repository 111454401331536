import { DateTime } from "luxon";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import { RECPATCHA_PUBLIC_KEY } from "../constants/api";
import times from "../constants/times";
import booking from "../services/booking";

export interface BookingFormData {
  name: string;
  email: string;
  phone: string;
  date: string;
  time: string;
  guests: number;
  token: string;
}

const BookingForm = () => {
  const ReCAPTCHARef = useRef<ReCAPTCHA>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<BookingFormData>();

  const onSubmit = async (data: BookingFormData) => {
    try {
      setIsLoading(true);
      const token = ReCAPTCHARef.current?.getValue();
      if (!token)
        return onError(
          "Une erreur interne est survenue en rapport avec le service anti robot."
        );
      await toast.promise(
        booking({
          ...data,
          token,
        }),
        {
          success: "Votre réservation a été confirmée avec succès !",
          pending: "En cours de réservation...",
          error:
            "Une erreur est survenue lors de la réservation. Veuillez réessayer.",
        }
      );
      reset();
    } finally {
      setIsLoading(false);
    }
  };

  const onError = (
    error = "Une erreur est survenue sur le formulaire de réservation"
  ) => toast.error(error);

  const validateNotSunday = (date: string) => {
    const selectedDate = DateTime.fromFormat(date, "yyyy-MM-dd").startOf("day");
    const now = DateTime.now();

    // Vérifie si la date est invalide
    if (!selectedDate.isValid) {
      return "Date invalide.";
    }

    // Vérifie si la date est un dimanche
    if (selectedDate.weekday === 7) {
      return "Les réservations ne sont pas autorisées le dimanche.";
    }

    // Vérifie si la date est dans le passé
    if (selectedDate < now.startOf("day")) {
      return "Les réservations pour une date passée ne sont pas autorisées.";
    }

    // Si toutes les conditions sont respectées, la validation est réussie
    return true;
  };

  return (
    <div
      id="je-reserve"
      className="flex justify-center items-center bg-secondary py-12"
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full md:max-w-5xl bg-white p-6 rounded-2xl shadow-lg"
      >
        <h2 className="text-2xl font-bold text-center mb-6 uppercase">
          Je réserve
        </h2>

        {/* Name and Email - Row 1 */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block text-sm font-medium mb-1" htmlFor="name">
              Nom <span className="text-red-600">*</span> :
            </label>
            <input
              type="text"
              id="name"
              className={`w-full px-4 py-2 rounded-md border ${
                errors.name ? "border-red-500" : "border-gray-300"
              } focus:outline-none focus:ring-2 focus:ring-blue-400`}
              {...register("name", { required: "Name is required" })}
            />
            {errors.name && (
              <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium mb-1" htmlFor="email">
              Email <span className="text-red-600">*</span> :
            </label>
            <input
              type="email"
              id="email"
              className={`w-full px-4 py-2 rounded-md border ${
                errors.email ? "border-red-500" : "border-gray-300"
              } focus:outline-none focus:ring-2 focus:ring-blue-400`}
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email address",
                },
              })}
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">
                {errors.email.message}
              </p>
            )}
          </div>
        </div>

        {/* Phone and Number of guests - Row 2 */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block text-sm font-medium mb-1" htmlFor="phone">
              Numéro de téléphone <span className="text-red-600">*</span> :
            </label>
            <input
              type="tel"
              id="phone"
              className={`w-full px-4 py-2 rounded-md border ${
                errors.phone ? "border-red-500" : "border-gray-300"
              } focus:outline-none focus:ring-2 focus:ring-blue-400`}
              {...register("phone", {
                required: "Phone number is required",
                pattern: {
                  value: /^[0-9]{10,15}$/,
                  message: "Invalid phone number",
                },
              })}
            />
            {errors.phone && (
              <p className="text-red-500 text-sm mt-1">
                {errors.phone.message}
              </p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium mb-1" htmlFor="guests">
              Nombre de personne(s) <span className="text-red-600">*</span> :
            </label>
            <input
              type="number"
              id="guests"
              className={`w-full px-4 py-2 rounded-md border ${
                errors.guests ? "border-red-500" : "border-gray-300"
              } focus:outline-none focus:ring-2 focus:ring-blue-400`}
              {...register("guests", {
                required: "Number of guests is required",
                min: { value: 1, message: "Must be at least 1 person" },
              })}
            />
            {errors.guests && (
              <p className="text-red-500 text-sm mt-1">
                {errors.guests.message}
              </p>
            )}
          </div>
        </div>

        {/* Date and Time - Row 3 */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block text-sm font-medium mb-1" htmlFor="date">
              Date <span className="text-red-600">*</span> :
            </label>
            <input
              type="date"
              id="date"
              className={`w-full px-4 py-2 rounded-md border ${
                errors.date ? "border-red-500" : "border-gray-300"
              } focus:outline-none focus:ring-2 focus:ring-blue-400`}
              {...register("date", {
                required: "Date is required",
                validate: validateNotSunday,
              })}
            />
            {errors.date && (
              <p className="text-red-500 text-sm mt-1">{errors.date.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium mb-1" htmlFor="time">
              Heure <span className="text-red-600">*</span> :
            </label>
            <Controller
              control={control}
              name="time"
              rules={{
                required: "Vous devez choisir une heure",
              }}
              render={({ field: { onChange } }) => (
                <Select
                  options={times.filter((time) => {
                    const timeObj = DateTime.fromFormat(time.value, "HH:mm");
                    const nowPlus15 = DateTime.now().plus({ minutes: 15 });
                    const tomorrow = DateTime.now().plus({ days: 1 }).startOf('day');
                  
                    // Inclure les horaires du lendemain si l'heure actuelle est après la dernière plage horaire
                    if (nowPlus15 > DateTime.fromFormat("23:00", "HH:mm")) {
                      return timeObj >= tomorrow;
                    }
                  
                    return timeObj >= nowPlus15;
                  })}
                  placeholder="Choisissez une heure"
                  className={`${
                    errors.time ? "border-red-500" : "border-gray-300"
                  }`}
                  onChange={(v) => onChange(v?.value)}
                />
              )}
            />
            {errors.time && (
              <p className="text-red-500 text-sm mt-1">{errors.time.message}</p>
            )}
          </div>
        </div>
        <p className="my-3">
          Les réservations sont possibles uniquement pendant les heures
          d'ouverture, du <span className="text-primary">lundi</span> au{" "}
          <span className="text-primary">samedi</span>, de{" "}
          <span className="text-primary">12h00</span> à{" "}
          <span className="text-primary">14h30</span> et de{" "}
          <span className="text-primary">18h30</span> à{" "}
          <span className="text-primary">22h30</span>.
        </p>
        {/* Submit Button */}
        <button
          type="submit"
          disabled={isLoading}
          className="w-full bg-primary text-white py-2 px-4 rounded-lg hover:opacity-75 disabled:opacity-50 disabled:cursor-not-allowed transition duration-200 mb-3"
        >
          Je réserve
        </button>
        <ReCAPTCHA ref={ReCAPTCHARef} sitekey={RECPATCHA_PUBLIC_KEY} />
      </form>
    </div>
  );
};

export default BookingForm;
